<template>
  <el-row class="table">
    <el-tabs v-model="activeName">
      <el-tab-pane
          v-for="item in tabMapOptions"
          :key="item.key"
          :label="item.label"
          :name="item.key"
        >
          <keep-alive>
            <lesson-list :type="activeName" ref="lessonList" :schoolArray="schoolArray" v-if="activeName==item.key" />
          </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
  import lessonList from './components/lessonList'
  import getMyCampus from '@/mixins/getMyCampus'
  export default {
    components: { lessonList },
    mixins: [getMyCampus],
    watch: {
      activeName(val) {
        this.$router.push(`${this.$route.path}?tab=${val}`)
      },
    },
    created() {
      const tab = this.$route.query.tab
      if (tab) {
        this.activeName = tab
      }
      let perms = JSON.parse(localStorage.getItem('perms'))
      perms.findIndex(list => {
        if (list === 'admin:all') {
          this.tabMapOptions = [
            {
              label: '我的排课',
              key: 'myLesson',
            },
            {
              label: '全部排课',
              key: 'allLesson',
            },
            {
              label: '校区排课',
              key: 'campusLesson',
            },
          ]
        } else {
          if (list === 'lesson:all') {
            this.tabMapOptions.push({
              label: '全部排课',
              key: 'allLesson',
            })
          } else if (list === 'lesson:campus') {
            this.tabMapOptions.push({
              label: '校区排课',
              key: 'campusLesson',
            })
          } else if (list === 'lesson:myLesson') {
            this.tabMapOptions.push({
              label: '我的排课',
              key: 'myLesson',
            })
          }
        }
      })
      this.activeName = this.tabMapOptions[0].key
    },
    data() {
      return {
        activeName: '',
        tabMapOptions: [],
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '~@/style/table.scss';
</style>
