<template>
  <!-- <div style="margin: 12px 24px"> -->
  <div>
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="12">
          <el-col :xl="6" :lg="12" :md="24">
            <el-form-item label="展示方式：">
              <el-select
                class="tb-smselect w100"
                v-model="seleShow"
                placeholder="请选择展示方式"
                @change="handleSelectChange"
              >
                <el-option
                  v-for="(item, index) in showList"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="12" :md="24">
            <el-form-item label="日期区间：">
              <date-picker ref="dataPicker" :dateTime="queryParam.dateTime" @updateView="datetTimeGet" class="w100" :disabled="seleShow !== 'list'"></date-picker>
            </el-form-item>
          </el-col>
          <el-col :xl="6" :lg="12" :md="24">
            <el-form-item label="班级名称：">
              <el-input
                v-model="queryParam.className"
                placeholder="请输入"
                class="w100"
              />
            </el-form-item>
          </el-col>
          <template v-if="advanced">
            <el-col :xl="6" :lg="12" :md="24">
              <el-form-item label="上课状态：">
                <el-select
                  v-model="queryParam.lessonState"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in lessonStateArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :lg="12" :md="24">
              <el-form-item label="课程类别：">
                <el-select
                  v-model="queryParam.type"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in typeArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :lg="12" :md="24">
              <el-form-item label="排课讲师：">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入"
                  class="w100"
                  :remote-method="remoteeduMethod"
                  v-model="queryParam.eduName">

                  <el-option v-for="item in staffList" :key="item.userId" :label="item.name" :value="item.userId"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :lg="12" :md="24">
              <el-form-item label="班级校区：">
                <el-select
                  v-model="queryParam.campusIdSet"
                  class="w100"
                  multiple
                  collapse-tags
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in schoolArray"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xl="6" :lg="12" :md="24">
              <el-form-item label="课堂反馈：">
                <el-select
                  v-model="queryParam.hasFeedback"
                  class="w100"
                  placeholder="请选择"
                  clearable
                  filterable
                >
                  <el-option
                    v-for="item in FeedbackArray"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
          <search-query
            @updateView="updateView"
            @clearQuery="clearQuery"
            @searchQuery="search"
          ></search-query>
        </el-row>
      </div>
    </el-form>
    <!-- dataList: {{dataList}} -->
    <!-- 表格 -->
    <el-card shadow="never" v-if="seleShow === 'list'">
      <div slot="header" class="flex">
        <span>排课数据</span>
        <el-popover
          placement="top-start"
          width="200"
          style="margin-left: 10px;"
          trigger="hover">
          <el-row class="condition-con">
            <el-row class="condition-flex"><span class="condition-span teacher"></span><span>讲师</span></el-row>
            <el-row class="condition-flex"><span class="condition-span assistant"></span><span>助教</span></el-row>
          </el-row>
          <span class="el-icon-question pointer" slot="reference"></span>
        </el-popover>
        <div class="flex_1"></div>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>
      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          @sort-change="tableSortAll"
          :default-sort="{ prop: 'start_time', order: 'ascending' }"
          style="margin-top: 15px;"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '校区'">
                <span v-if="scope.row.campusInfo !== null">{{ scope.row.campusInfo.name }}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '班级名称'">
                <span v-if="scope.row.classInfo !== null">{{ scope.row.classInfo.name }}</span>
                <span v-else>--</span>
              </template>
              <template v-else-if="item.label === '上课时间'">
                <div class="column-link" @click="particulars(scope.row)">
                  <span>（{{scope.row.start_time.substring(0, 10) | weekDay}}）</span>
                  <span>{{ scope.row.start_time | formatDateStart }}</span>
                  <span>{{ scope.row.end_time | formatDateEnd }}</span>
                </div>
              </template>
              <template v-else-if="item.label === '课堂任务'">
                <template v-if="scope.row.courseUnitTitleList.length === 0">
                  <span class="column-link" @click="manageLessTask(scope.row)">去选择</span>
                </template>
                <template v-else>
                <span v-for="(item, index) in scope.row.courseUnitTitleList" :key="index">
                  <span class="column-link" @click="manageLessTask(scope.row)">
                    {{item}}<span v-if="(index+1) !== scope.row.courseUnitTitleList.length">、</span>
                  </span>
                </span>
                </template>
              </template>
              <template v-else-if="item.label === '上课老师'">
                <template v-if="scope.row.lessonTeacherList && scope.row.lessonTeacherList.length !== 0">
                  <el-row v-for="(item, key) in scope.row.lessonTeacherList" :key="key + 'zjName'">
                    <el-row v-if="item.type === '主教' && Boolean(item.teacher)" class="zjName mb-10">{{item.teacher.name}}</el-row>
                  </el-row>

                  <span v-for="(item, key) in scope.row.lessonTeacherList" :key="key + 'assistant'">
                    <span v-if="item.type === '助教' && Boolean(item.teacher)" class="assistant">{{item.teacher.name}}</span>
                    <!-- <span v-else>--</span> -->
                  </span>

                  <!-- <el-row v-if="scope.row.zjName" class="mb-10">
                    <el-tag v-for="(item, key) in scope.row.zjName.split(',')" :key="key + 'zjName'" type="success">
                      {{item}}
                    </el-tag>
                  </el-row>
                  <el-row v-if="scope.row.assistant">
                    <el-tag type="warning" v-for="(item, key) in scope.row.assistant.split(',')" :key="key + 'assistant'" class="mr-10">
                      {{item}}
                    </el-tag>
                  </el-row> -->
                </template>
              </template>
              <template v-else-if="item.label === '类型'">
                {{scope.row.type}}
              </template>
              <template v-else-if="item.label === '上课学生'">
                <span
                  class="column-link"
                  v-if="scope.row.lessonStudentList"
                  @click="checkStu(scope.row)"
                >
                  {{scope.row.lessonStudentList.length}}人
                </span>
              </template>
              <template v-else-if="item.label === '状态'">
                <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getLessonFinishState(scope.row.finished_status)
                  "
                ></span>
                <span v-text="scope.row.finished_status"></span>
              </template>
              <template v-else-if="item.label === '课堂反馈'">
                 <span
                  class="span-state"
                  :style="
                    'backgroundColor' + ':' + getLessonFinishState(scope.row.hasFeedback)
                  "
                ></span>
                <span v-text="scope.row.hasFeedback"></span>
              </template>
              <template v-else-if="item.label === '排课操作'">
                <el-button type="text" @click="toClass(scope.row)"
                  >去上课</el-button
                >
                <el-divider direction="vertical"></el-divider>
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    更 多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="editDeedBack(scope.row)" v-has="'lesson:feedback'">编辑课堂反馈</el-dropdown-item>
                    <el-dropdown-item @click.native="rollCall(scope.row)" v-has="'class:callStudent'" v-if="scope.row.studentNum !== 0">点 名</el-dropdown-item>
                    <el-dropdown-item @click.native="editLess(scope.row)" v-has="'class:editLessonInfo'" v-show="!(scope.row.finished_status === '已上课') && scope.row.type === '常规'">
                      编辑排课
                    </el-dropdown-item>
                    <el-dropdown-item v-show="scope.row.finished_status === '已上课' && scope.row.type === '常规'" @click.native="applyCourse(scope.row)">补课申请</el-dropdown-item>
                    <el-dropdown-item @click.native="delLesson(scope.row)" v-has="'class:delLesson'" v-show="!(scope.row.finished_status === '已上课')">删除排课</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>

              <template v-else-if="item.label === '直播间操作'">
                <template v-if="Boolean(scope.row.liveId)">
                  <el-button type="text" @click="jumpLive(scope.row)"
                    >去直播</el-button
                  >
                  <el-divider direction="vertical"></el-divider>
                  <el-button type="text" @click="codeManage(scope.row)"
                    >参加码</el-button>
                  <el-divider direction="vertical"></el-divider>
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      更 多<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="addLive(scope.row)">重建直播间</el-dropdown-item>
                      <el-dropdown-item @click.native="editLive(scope.row)">编辑直播间</el-dropdown-item>
                      <!-- <el-dropdown-item @click.native="SyncClassNormal(scope.row)">删除直播间</el-dropdown-item> -->
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
                <template v-else>
                   <el-button type="text" @click="addLive(scope.row)" :disabled="Boolean(classInfo.finished_date) && (classInfo.finished_date !== '0001-01-01') && (classInfo.finished_date !== '1900-01-01')"
                    >创建直播间</el-button>
                </template>
              </template>
              <!-- <template v-else-if="item.label === '操作'">
                <el-dropdown size="small" split-button type="primary" class="mr-10" @click="toClass(scope.row)">
                  去上课
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="manageLessTask(scope.row)">管理课堂任务</el-dropdown-item>
                    <el-dropdown-item @click.native="rollCall(scope.row)" v-show="scope.row.studentNum !== 0">点 名</el-dropdown-item>
                    <el-dropdown-item @click.native="editLess(scope.row)" v-has="'class:editLessonInfo'" v-show="!(scope.row.finished_status === '已上课') && scope.row.type === '常规'">编 辑</el-dropdown-item>
                    <el-dropdown-item v-show="scope.row.finished_status === '已上课' && scope.row.type === '常规'" @click.native="applyCourse(scope.row)" >补课申请</el-dropdown-item>
                    <el-dropdown-item @click.native="delLesson(scope.row)" v-has="'class:delLesson'" v-show="!(scope.row.finished_status === '已上课')">删 除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-dropdown size="small" split-button type="primary" v-if="Boolean(scope.row.liveId)" @click="jumpLive(scope.row)">
                  去直播
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="codeManage(scope.row)">管理参加码</el-dropdown-item>
                    <el-dropdown-item @click.native="addLive(scope.row)">重建直播间</el-dropdown-item>
                    <el-dropdown-item @click.native="editLive(scope.row)">编辑直播间</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button v-else type="info" size="small" @click="addLive(scope.row)">新增直播间</el-button>

                <el-dropdown style="margin-left: 10px;">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="editDeedBack(scope.row)" v-has="'lesson:feedback'">编辑课堂反馈</el-dropdown-item>
                    <el-dropdown-item @click.native="particulars(scope.row)">查看上课情况</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template> -->
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <el-pagination
        style="float: right;margin-right: 20px;"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>
    </el-card>
    <!-- 日历试图 -->
    <lesson-calendar v-if="seleShow !== 'list'" :calendarList="calendarList" @updateView="getCalendarList"></lesson-calendar>

    <!-- 参加码管理 -->
    <code-manage
      :show="dialogCode"
      :liveRoomId="liveRoomId"
      @close="dialogCode=false"
    ></code-manage>

    <!-- 补课学员列表dialog -->
    <applay-course :applyCourseData="applyCourseData" :show="applyCourseFlag" @close="applyCourseFlag=false"></applay-course>

    <!-- 查看学员dialog -->
    <check-stu :lessonData="lessonData" :show="dialogStu" @close="dialogStu=false"></check-stu>

    <!-- 点名 -->
    <lesson-attendance :show="attendanceDialog" @close="attendanceDialog=false" :productId="product_id"  :lesson_id="lessonId"  @updateView="switchClass(type)"  :className="className"  :schooltime="schooltime"></lesson-attendance>

    <!-- 管理课堂任务 -->
    <manage-lesson :show="lessonDialog" @close="lessonDialog=false;courseUnitIdList=[]" :lessonId="lessonId" :courseId="courseId" :classId="classId" @updateView="switchClass(type)" :courseUnitIdList="courseUnitIdList"></manage-lesson>

    <!-- 进入直播间 -->
    <join-live :show="dialogJionLive" @close="dialogJionLive=false" :liveRoomId="liveRoomId"></join-live>

    <add-lesson
      :addlessonData="addlessonData"
      :show="dialogLesson"
      :campusId="classInfo.campus_id"
      :classInfo="classInfo"
      @close="dialogLesson  = false"
      @editLessonForm="editLessonForm"
      @getClassMessage="switchClass(type)"
    ></add-lesson>
    <!-- 冲突检测 -->
    <detection-clash :show="clashDialog" @close="clashDialog=false;clashData=[]" :clashData="clashData"></detection-clash>

    <!-- 课堂反馈 -->
    <deed-back :show="DeedBackDialog" @close="DeedBackDialog=false" :lesson_id="lesson_id" @updateView="switchClass(type)"></deed-back>

    <!-- 排详情 -->
    <lesson-details :show="particularsDialog" @close="particularsDialog=false;lessonData={}" :lessonData="lessonData"></lesson-details>

    <!-- 新建直播间 -->
    <add-live
     :show="dialogLive"
     :liveName="lessonLiveName"
     :liveData="liveData"
     :lessonData="lessonLiveData"
     :isLessonReCreate="true"
      @close="dialogLive=false,liveData=null,lessonLiveData=null"
      @addLiveSave="addLiveSave"
      @editLiveSave="editLiveSave"
    ></add-live>

  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import getStateColor from '@/mixins/getStateColor'
  import datePicker from '@/components/date-picker'
  import DeedBack from '@/components/DeedBack'
  import { formatDateYMD } from '@/utils/formatDate'
  import applayCourse from '@/components/senate/applay-course'
  import codeManage from '@/components/senate/detail-pane/components/code-manage'
  import { getLessonList,getLessonlistMyCampus,getLessonlistAll,lessonEdit } from '@/api/senate/lesson'
  import { delLesson,listCourseUnit} from '@/api/senate/class'
  import detectionClash from '@/components/senate/detection-clash'
  import joinLive from '@/components/senate/detail-pane/components/join-live'
  import lessonCalendar from '../lesson-calendar'
  import addLesson from '@/components/senate/add-lesson'
  import { getListEmployee } from '@/api/system/staff'
  import checkStu from '@/components/senate/check-student/index'
  import lessonAttendance from '@/components/senate/detail-pane/components/lesson-attendance'
  import manageLesson from '@/components/senate/manage-lesson/index'
  import { formatDate } from '@/utils/formatDate'
  import { getFeedback } from '@/api/senate/lesson'
  import LessonDetails from '@/components/senate/manage-lesson/LessonDetails'
  import addLive from '@/components/senate/add-live'
  import { insertClassBliveRoom, updateClassBliveRoomById } from '@/api/senate/class'

  // 默认展示列
  const baseColSetting = [
    {
      label: '校区',
      prop: 'campusName',
      state: true,
      align: 'center',
      width: '120'
    },
    {
      label: '班级名称',
      prop: 'productName',
      state: true,
      align: 'center',
      width: '160'
    },
    {
      label: '上课时间',
      state: true,
      align: 'center',
      sortable: true,
      prop: 'start_time',
      width: '235',
    },
    {
      label: '课堂任务',
      state: true,
      align: 'center',
      width: '150'
    },
    {
      label: '类型',
      state: false,
      align: 'center',
      width: '100'
    },
    {
      label: '上课老师',
      state: true,
      align: 'center',
      width: '150'
    },
    {
      label: '上课教室',
      prop: 'classroomTitle',
      state: false,
      align: 'center',
      width: '100'
    },
    {
      label: '上课学生',
      state: true,
      align: 'center',
      width: '80'
    },
    {
      label: '状态',
      prop: 'status',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '课堂反馈',
      prop: 'hasFeedback',
      state: false,
      align: 'center',
      width: '100'
    },
    {
      label: '排课操作',
      state: true,
      align: 'center',
      width: '150',
    },
    {
      label: '直播间操作',
      state: true,
      align: 'center',
      width: '200',
    },
  ]
  export default {
    components: { TextButton, SearchQuery,datePicker,detectionClash,addLesson,joinLive, codeManage, lessonCalendar, checkStu, manageLesson, lessonAttendance,applayCourse, DeedBack, LessonDetails, addLive },
    props: {
      type: {
        default: '',
        type: String,
        require: true,
      },
      schoolArray: {
        default: () => {
          return []
        },
        type: Array
      }
    },
    watch: {
      type(val) {
        this.switchClass(val)
      }
    },
    mixins: [getStateColor],
    created() {
      const end = new Date();
      const start = new Date();
      const weekday = start.getDay() === 0 ? start.getDay() + 7 : start.getDay();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
      end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
      this.queryParam.dateTime = [formatDateYMD(start),formatDateYMD(end)]
      this.minStartTime = formatDateYMD(start)
      this.maxStartTime = formatDateYMD(end)
      this.switchClass(this.type)
    },
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },
    data() {
      return {
        addlessonData:{},
        tableKey: 1,
        // 查询参数
        queryParam: {isFinish:false},
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 30, 40],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        advanced: false,
        FeedbackArray: [
          {
            value: true,
            label: '已填写'
          },
          {
            value: false,
            label: '未填写'
          }
        ],
        lessonStateArray: [
          {
            value: '未上课',
            label: '未上课',
          },
          {
            value: '已上课',
            label: '已上课',
          },
        ],
        typeArray: [
          {
            value: '常规',
            label: '常规',
          },
          {
            value: '试听',
            label: '试听',
          },
        ],
        classIsAsc: false, // 默认为升序
        minStartTime: '',
        maxStartTime: '',
        calendarList: [], // 日历数据
        dialogStu: false,
        lessonData: {},
        lessonDialog: false,
        lessonId: '',
        classId: '',
        courseId:'',
        courseUnitIdList: [],
        attendanceDialog: false,
        schooltime: "",
        className: "",
        product_id: "",
        lessonStudentDialog:false,
        dataList: [],
        chooseDate: [],
        clashDialog: false,
        clashData: [],
        minStartTime: '',
        maxStartTime: '',
        dialogJionLive: false,
        dialogCode: false,
        liveRoomId: '',
        seleShow: 'list',
        staffList:[],
        applyCourseData: {},
        applyCourseFlag: false,
        showList: [
          {
            label: '按列表展示',
            value: 'list'
          },
          {
            label: '按日历展示',
            value: 'calendar'
          }
        ],
        dialogLesson:false,
        classInfo:{},
        DeedBackDialog: false,
        lesson_id: '',
        class_id: '',
        particularsDialog: false,
        dialogLive: false,
        liveData: null,
        lessonLive: false,
        lessonLiveName: '',
        lessonLiveData: null,
      }
    },
    methods: {
      // 处理冲突
      clashFun (res) {
        if (res.body && res.body.length > 0) {
          var newArr = [];
          res.body.forEach((item, index) => {
            item.conflictType = item.conflictType +""
            var flag = true
            if (newArr.length > 0) {
              for (var i= 0; i<newArr.length; i++) {
                if (newArr[i].id === item.id && newArr[i].conflictType !== item.conflictType) {
                  newArr[i].conflictType = [newArr[i].conflictType, item.conflictType],
                    newArr[i].classroomId = newArr[i].classroomId ? newArr[i].classroomId : item.classroomId,
                    newArr[i].classroomTitle = newArr[i].classroomTitle ? newArr[i].classroomTitle : item.classroomTitle,
                    newArr[i].teacherId = newArr[i].teacherId ? newArr[i].teacherId : item.teacherId,
                    newArr[i].teacherName = newArr[i].teacherName ? newArr[i].teacherName : item.teacherName,
                    newArr[i].teacherType = newArr[i].teacherType ? newArr[i].teacherType : item.teacherType,
                    flag = false
                  break
                }
              }
            }
            if (flag) {
              newArr.push(item)
            }
          })
        }
        this.clashData = newArr
        this.clashDialog = true
      },
      // 编辑排课
      async editLessonForm (data) {
        let start_time = data.start_time.substring(0, 19)
        let hour = data.hour
        let minute = data.minute
        let b = new Date(Number(new Date(start_time).getTime()) + (Number(hour) * 3600 * 1000) + (Number(minute) * 60 * 1000))
        let end_time = formatDate(b, 'yyyy-MM-dd hh:mm:ss')
        let classroom_id = null
        if (data.roomIds && data.roomIds.length !== 0) {
          classroom_id = data.roomIds[0]
        } else {
          if (!data.classroom_id) {
            classroom_id = null
          } else {
            classroom_id = data.classroom_id
          }
        }
        let editLesson = {
          id: data.id,
          course_unit_id: '',
          start_time: data.start_time.substring(0, 19),
          end_time: end_time,
          classroom_id: classroom_id
        }
        const res = await lessonEdit({ ...editLesson })
        if (res.state === 'success') {
          window.$msg('编辑成功')
          this.dialogLesson = false
          this.switchClass(this.type)
        } else {
          if (res.errCode === '0201001') {
            this.clashFun(res)
          }
        }
      },

      // 编辑排课
      editLess (row) {
        let studentIds = []
        if (row.lessonStudentList.length !== 0) {
          row.lessonStudentList.map(item => {
            studentIds.push(item.student_id)
          })
        }
        this.classInfo = row.classInfo
        row.classInfo.classId = row.class_id
        // this.classInfo.zjName = 'libin'
        let zjName = this.classInfo.zjName.substring(0, row.classInfo.zjName.length - 1)
        let assistant = this.classInfo.assistant.substring(0, row.classInfo.assistant.length - 1)
        let zjUserIds = this.classInfo.zjUserIds.substring(0, row.classInfo.zjUserIds.length - 1)
        let assistantUserIds = this.classInfo.assistantUserIds.substring(0, row.classInfo.assistantUserIds.length - 1)

        this.$set(this.classInfo,"zjName", zjName)
        this.$set(this.classInfo,"assistant", assistant)
        this.$set(this.classInfo,"zjUserIds", zjUserIds)
        this.$set(this.classInfo,"assistantUserIds", assistantUserIds)

        // let editData = {
        //   ...row
        // }
        this.listCourseUnit(row)
      },

      async listCourseUnit (row) {
        const res = await listCourseUnit({
          lessonId: row.id
        })
        if (res.state === 'success') {
          var courseUnitId = []
          for (let i = 0; i < res.body.length; i++) {
            courseUnitId.push(res.body[i].id)
          }
          this.addlessonData = row
          this.addlessonData.courseUnitId = courseUnitId
          this.addlessonData.zjName = ''
          this.addlessonData.assistant = ''
          this.addlessonData.zjUserIds = ''
          this.addlessonData.assistantUserIds = ''
          if (this.addlessonData.lessonTeacherList && this.addlessonData.lessonTeacherList.length !== 0) {
            this.addlessonData.lessonTeacherList.forEach(ele => {
              if (ele.type === '主教') {
                this.addlessonData.zjName = ele.teacher.name
                this.addlessonData.zjUserIds = ele.teacher.id
              } else if (ele.type === '助教') {
                this.addlessonData.assistant += ele.teacher.name +','
                this.addlessonData.assistantUserIds += ele.teacher.id+','
              }
            })
          }

          let assistant = this.addlessonData.assistant.substring(0, this.addlessonData.assistant.length - 1)
          let assistantUserIds = this.addlessonData.assistantUserIds.substring(0, this.addlessonData.assistantUserIds.length - 1)

          this.$set(this.addlessonData,"assistant", assistant)
          this.$set(this.addlessonData,"assistantUserIds", assistantUserIds)

          this.dialogLesson = true
        }
      },
      applyCourse (row) {
        if (row.courseUnitTitleList.length === 0) {
          window.$msg('请先关联课节后再申请补课', 2)
          return false
        }
        row.courseUnitlist = row.courseUnitList
        this.applyCourseData = row
        this.applyCourseFlag = true
      },
      // 删除排课
      delLesson (row) {
        this.$confirm('确定删除当前排课吗？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.delectLesson(row)
          })
          .catch(() => {
            return false
          })
      },
      async delectLesson (row) {
        const res = await delLesson({
          ids: [row.id]
        })
        if (res.state === 'success') {
          window.$msg('删除成功')
          this.switchClass(this.type)
        } else {
          window.$msg(res.errMsg, 2)
        }
      },
      remoteeduMethod(query) {
        this.staffList = []
        if (query.trim() !== '') {
          this.listEmployee(query)
        }
      },
      async listEmployee (query) {
        const res = await getListEmployee({
          pageNum: 1,
          pageSize: 10000,
          name:query
        })
        this.staffList = res.body.list
      },
      datetTimeGet(data) {
        if(data === null) {
          this.minStartTime = ''
          this.maxStartTime =  ''
        }else {
          this.minStartTime = data[0]
          this.maxStartTime =  data[1]
        }

      },
      switchClass(type) {
        switch (type) {
          case 'allLesson':
            return this.getLessonlistAll()
          case 'campusLesson':
            return this.getLessonlistMyCampus()
          case 'myLesson':
            return this.getTeacherLessonList()
        }
      },

      search() {
        this.pagination.currentPage = 1
        this.switchClass(this.type)
      },

      // 获取老师排课的班级
      async getTeacherLessonList () {
        this.calendarList = []
        const res = await getLessonList({
          pageNum: this.seleShow === 'list' ? this.pagination.currentPage : 1,
          pageSize: this.seleShow === 'list' ? this.pagination.pageSize : 9999,
          campusIdSet:this.queryParam.campusIdSet,
          className:this.queryParam.className,
          type:this.queryParam.type,
          teacherId:this.queryParam.eduName,
          hasFeedback: this.queryParam.hasFeedback,
          finishedStatus:this.queryParam.lessonState,
          minStartTime: this.minStartTime ? this.minStartTime + ' 00:00:00' : '',
          maxStartTime: this.maxStartTime ? this.maxStartTime + ' 23:59:59' : ''
        })
        const dataList = res.body.list
        dataList.forEach((ele, idx) => {
          ele.hasFeedback = null
          if (this.seleShow === 'list') {
            this.getFeedback(ele.id, idx)
          }
          this.calendarList.push({
            ...ele,
            title: this.setTitle(ele),
            start: ele.start_time.substring(0, 19),
            end: ele.end_time ? ele.end_time.substring(0, 19) : '',
            color: ele.finished_status === '已上课' ? '#BAD8E7' : '#BBBDBE',
            name: ele.teacherInfo ? ele.teacherInfo.name.substr(0, 1) : ''
          })
        })
        this.dataList = dataList
        this.pagination.total = res.body.total
        // this.fcEvents = this.calendarList // 往日历上挂在数据
      },

      // 获取老师排课的班级
      async getLessonlistMyCampus () {
        this.calendarList = []
        const res = await getLessonlistMyCampus({
          pageNum: this.seleShow === 'list' ? this.pagination.currentPage : 1,
          pageSize: this.seleShow === 'list' ? this.pagination.pageSize : 9999,
          campusIdSet:this.queryParam.campusIdSet,
          className:this.queryParam.className,
          type:this.queryParam.type,
          isDesc: this.classIsAsc,
          teacherId:this.queryParam.eduName,
          hasFeedback: this.queryParam.hasFeedback,
          finishedStatus:this.queryParam.lessonState,
          minStartTime: this.minStartTime ? this.minStartTime + ' 00:00:00' : '',
          maxStartTime: this.maxStartTime ? this.maxStartTime + ' 23:59:59' : ''
        })
        const dataList = res.body.list
        dataList.forEach((ele, idx) => {
          ele.hasFeedback = null
          if (this.seleShow === 'list') {
            this.getFeedback(ele.id, idx)
          }
          this.calendarList.push({
            ...ele,
            title: this.setTitle(ele),
            start: ele.start_time.substring(0, 19),
            end: ele.end_time ? ele.end_time.substring(0, 19) : '',
            color: ele.finished_status === '已上课' ? '#BAD8E7' : '#BBBDBE',
            name: ele.teacherInfo ? ele.teacherInfo.name.substr(0, 1) : ''
          })
        })
        this.dataList = dataList
        this.pagination.total = res.body.total
        // this.fcEvents = this.calendarList // 往日历上挂在数据
      },

      // 获取老师排课的班级
      async getLessonlistAll () {
        this.calendarList = []
        this.dataList = []
        const res = await getLessonlistAll({
          pageNum: this.seleShow === 'list' ? this.pagination.currentPage : 1,
          pageSize: this.seleShow === 'list' ? this.pagination.pageSize : 9999,
          campusIdSet:this.queryParam.campusIdSet,
          className:this.queryParam.className,
          type:this.queryParam.type,
          teacherId:this.queryParam.eduName,
          isDesc: this.classIsAsc,
          hasFeedback: this.queryParam.hasFeedback,
          finishedStatus:this.queryParam.lessonState,
          minStartTime: this.minStartTime ? this.minStartTime + ' 00:00:00' : '',
          maxStartTime: this.maxStartTime ? this.maxStartTime + ' 23:59:59': ''
        })
        const dataList = res.body.list
        dataList.forEach((ele, idx) => {
          ele.hasFeedback = null
          if (this.seleShow === 'list') {
            this.getFeedback(ele.id, idx)
          }
          this.calendarList.push({
            ...ele,
            title: this.setTitle(ele),
            start: ele.start_time.substring(0, 19),
            end: ele.end_time ? ele.end_time.substring(0, 19) : '',
            color: ele.finished_status === '已上课' ? '#BAD8E7' : '#BBBDBE',
            name: ele.teacherInfo ? ele.teacherInfo.name.substr(0, 1) : ''
          })
        })

        this.dataList = dataList
        this.pagination.total = res.body.total
        // this.fcEvents = this.calendarList // 往日历上挂在数据
      },
      // 班级列表排序
      tableSortAll(column) {
        switch (column.order) {
          case 'ascending':
            this.classIsAsc = false
            break
          case 'descending':
            this.classIsAsc = true
        }
        this.switchClass(this.type)
      },


      getFeedback(id, idx) {
        getFeedback({
          lessonId: id
        }).then(res=> {
          if (res.state === 'success') {
            this.dataList[idx].hasFeedback = Boolean(res.body.feedback) ? '已填写' : '未填写'
          }
        })
      },

      updateView(data) {
        this.advanced = data
      },

      // 清空选项
      clearQuery() {
        this.queryParam = {}
        this.$refs.dataPicker.time = []
        this.maxStartTime = ''
        this.minStartTime = ''
        this.staffList = []
      },

      update(data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },
      // 点名
      rollCall (row) {
        if (row.courseUnitTitleList.length === 0) {
          window.$msg('您还未选择课堂任务，请先选择课堂任务', 2)
        } else {
          this.lessonId = row.id
          if(row.type === "试听") {
            this.product_id = ""
            this.className = "试听课程"
          }else {
            this.product_id = row.classInfo.product_id
            this.className = row.classInfo.name
          }

          const startTime = row.start_time.substring(0,19) + '~'
          const endTime = row.end_time.substring(11,19)
          this.schooltime = startTime + endTime
          this.attendanceDialog = true
        }
      },
      codeManage (row) {
        this.liveRoomId = row.liveId
        this.dialogCode = true
      },

      getQueryDate () {
        if (!this.chooseDate) {
          this.minStartTime = ''
          this.maxStartTime = ''
        } else {
          this.minStartTime = this.datefmt(this.chooseDate[0]) + ' 00:00:00'
          this.maxStartTime = this.datefmt(this.chooseDate[1]) + ' 23:59:59'
        }
      },

      // dateKey 时间处理
      datefmt (date) {
        let res = ''
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        if (month < 10) {
          month = '0' + month
        }
        let day = date.getDate()
        if (day < 10) {
          day = '0' + day
        }
        res = `${year}-${month}-${day}`
        return res
      },

      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        this.switchClass(this.type)
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        this.switchClass(this.type)
      },

      getCalendarList(start, end) {
        this.minStartTime = start + ' 00:00:00'
        this.maxStartTime = end + ' 23:59:59'
        this.switchClass(this.type)
      },

      // 设置标题
      setTitle (ele) {
        const start = formatDate(new Date(ele.start_time), 'hh:mm')
        const end = formatDate(new Date(ele.end_time), 'hh:mm')
        let setTitle = ""
        if(ele.classInfo !== null) {
          setTitle = `${start}-${end} ${ele.classInfo.name}`
        }else {
          setTitle = `${start}-${end}`+"试听课"
        }
        return setTitle
      },

      // 查看课程目录
      showCourseList (row, className) {
        this.$router.push({
          path: '/senate/preview-course',
          query: {
            courseId: row.classInfo.course_id,
            classID: row.class_id,
            className: className
          }
        })
      },

      // 去直播
      jumpLive (row) {
        this.liveRoomId = row.liveId
        this.dialogJionLive = true
      },

      // 选择展示方式
      handleSelectChange (val) {
        const end = new Date();
        const start = new Date();
        const weekday = start.getDay()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * (weekday - 1) );
        end.setTime(end.getTime() + 3600 * 1000 * 24 * (7 - weekday));
        this.queryParam.dateTime = [formatDateYMD(start),formatDateYMD(end)]
        this.minStartTime = formatDateYMD(start)
        this.maxStartTime = formatDateYMD(end)
        if (val === 'list') {
          this.switchClass(this.type)
        }
      },

      // 查看学员
      checkStu (row) {
        if(row.type === "试听") {
          this.lessonId = row.id
          this.lessonStudentDialog = true
        }else if(row.type === "常规") {
          this.lessonData = row
          this.lessonData.class_id = row.classInfo.id
          this.dialogStu = true
        }
      },

      // 管理课堂任务
      manageLessTask (row) {
        if (row.courseUnitIdList.length !== 0) {
          this.courseUnitIdList = row.courseUnitIdList
        }
        this.lessonId = row.id
        this.classId = row.class_id
        this.courseId = row.courseId
        this.lessonDialog = true
      },

      // 去上课
      toClass (row) {
        if (row.courseUnitTitleList.length === 0) {
          window.$msg('您还未选择课堂任务，请先选择课堂任务', 2)
        } else {
          this.$router.push({
            path: '/senate/classroom-task',
            query: {
              lessonObject:JSON.stringify(row),
              classId: row.class_id,
              lessonId: row.id
            }
          })
        }
      },

      // 编辑课堂反馈
      editDeedBack(row) {
        this.lesson_id = row.id
        this.DeedBackDialog = true
      },

      particulars(row) {
        this.lessonData = row
        this.particularsDialog = true
      },

      addLive (row) {
        if (row.type === '常规') {
          this.lessonLiveName = `${row.classInfo.name} 第${row.num}节`
          this.class_id = row.class_id
        } else if (row.type === '试听') {
          this.lessonLiveName = `【试听】${row.courseName} ${row.start_time}`
          this.class_id = ''
        }
        this.lessonLiveData = row
        this.dialogLive = true
      },

      editLive(row) {
        if (row.type === '常规') {
          this.class_id = row.class_id
        } else if (row.type === '试听') {
          this.class_id = ''
        }
        this.liveData = row
        this.dialogLive = true
      },

      async addLiveSave(data) {
        await insertClassBliveRoom({
          title: data.title,
          start_time: data.startTime,
          end_time: data.endTime,
          type: data.type,
          max_users: data.type === 4 ? data.max_users : null,
          max_backup_users: data.type === 4 ? data.max_backup_users : null,
          class_id: this.class_id ? this.class_id : null,
          lesson_id: data.lesson_id,
          source: data.source,
        })
        window.$msg('新增成功')
        this.switchClass(this.type)
      },

      async editLiveSave(data) {
        await updateClassBliveRoomById({
          id: data.id,
          title: data.title,
          start_time: data.startTime,
          end_time: data.endTime,
          type: data.type,
          max_users: data.type === 4 ? data.max_users : null,
          max_backup_users: data.type === 4 ? data.max_backup_users : null,
          class_id: this.class_id ? this.class_id : null,
          lesson_id: data.lesson_id,
          source: data.source,
        })
        window.$msg('编辑成功')
        this.switchClass(this.type)
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "~@/style/container.scss";
</style>
