var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "full-calendar",
        {
          attrs: { events: _vm.fcEvents, lang: "zh" },
          on: { eventClick: _vm.fetchEvent, change: _vm.changeDateRange },
        },
        [
          _c("template", { slot: "fc-body-card" }, [
            _vm.interviewEvent
              ? _c(
                  "div",
                  { staticClass: "card-content clearfix" },
                  [
                    _c(
                      "el-dropdown",
                      {
                        staticClass: "card-dropdown",
                        attrs: {
                          placement: "bottom-end",
                          "split-button": "",
                          size: "medium",
                        },
                      },
                      [
                        _vm._v(" 管理 "),
                        _c(
                          "el-dropdown-menu",
                          { attrs: { slot: "dropdown" }, slot: "dropdown" },
                          [
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.toClass(_vm.interviewEvent)
                                  },
                                },
                              },
                              [_vm._v("去上课")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: !Boolean(
                                    _vm.interviewEvent.classLiveOpenInfo
                                  ),
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.jumpLive(_vm.interviewEvent)
                                  },
                                },
                              },
                              [_vm._v("去直播")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.manageLessTask(
                                      _vm.interviewEvent
                                    )
                                  },
                                },
                              },
                              [_vm._v("管理课堂任务")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                attrs: {
                                  disabled: !Boolean(
                                    _vm.interviewEvent.classLiveOpenInfo
                                  ),
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.codeManage(_vm.interviewEvent)
                                  },
                                },
                              },
                              [_vm._v("管理参加码")]
                            ),
                            _c(
                              "el-dropdown-item",
                              {
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.checkStu(_vm.interviewEvent)
                                  },
                                },
                              },
                              [_vm._v("查看学员列表")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(
                        "校区：" + _vm._s(_vm.interviewEvent.campusInfo.name)
                      ),
                    ]),
                    _vm.interviewEvent.type === "常规"
                      ? _c("p", { staticClass: "card-info" }, [
                          _vm._v(
                            "班级：" + _vm._s(_vm.interviewEvent.classInfo.name)
                          ),
                        ])
                      : _vm._e(),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v("上课时间："),
                      _c("span", {
                        domProps: {
                          textContent: _vm._s(
                            _vm.lessonTime(_vm.interviewEvent)
                          ),
                        },
                      }),
                    ]),
                    Boolean(_vm.interviewEvent.teacherInfo)
                      ? [
                          _c("p", { staticClass: "card-info" }, [
                            _vm._v(
                              "老师：" +
                                _vm._s(_vm.interviewEvent.teacherInfo.name)
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(
                        "学生：" +
                          _vm._s(
                            _vm.interviewEvent.lessonStudentList.length + "人"
                          )
                      ),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(
                        "状态：" + _vm._s(_vm.interviewEvent.finished_status)
                      ),
                    ]),
                    _c("p", { staticClass: "card-info" }, [
                      _vm._v(" 课堂任务： "),
                      _vm.interviewEvent.courseUnitTitleList.length === 0
                        ? _c("span", [_vm._v("暂无")])
                        : _c(
                            "span",
                            _vm._l(
                              _vm.interviewEvent.courseUnitTitleList,
                              function (item, index) {
                                return _c("span", { key: index }, [
                                  _vm._v(" " + _vm._s(item)),
                                  index + 1 !==
                                  _vm.interviewEvent.courseUnitTitleList.length
                                    ? _c("span", [_vm._v("、")])
                                    : _vm._e(),
                                ])
                              }
                            ),
                            0
                          ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("code-manage", {
        attrs: { show: _vm.dialogCode, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogCode = false
          },
        },
      }),
      _c("check-stu", {
        attrs: { lessonData: _vm.lessonData, show: _vm.dialogStu },
        on: {
          close: function ($event) {
            _vm.dialogStu = false
          },
        },
      }),
      _c("manage-lesson", {
        attrs: {
          show: _vm.lessonDialog,
          lessonId: _vm.lessonId,
          classId: _vm.classId,
          courseUnitIdList: _vm.courseUnitIdList,
        },
        on: {
          close: function ($event) {
            _vm.lessonDialog = false
            _vm.courseUnitIdList = []
            _vm.showCard = false
          },
          updateView: _vm.updateView,
        },
      }),
      _c("join-live", {
        attrs: { show: _vm.dialogJionLive, liveRoomId: _vm.liveRoomId },
        on: {
          close: function ($event) {
            _vm.dialogJionLive = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }